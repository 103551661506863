import React from "react";
import "./FeatureCard.scss";

export default function FeatureCard({ children, className = null }) {
	return (
		<div className={`feature-card ${className ?? ''}`}>
			{children}
		</div>
	);
}
