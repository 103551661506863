import React, { useEffect, useState } from "react";
import "./IPInfo.scss";

export default function IPInfo() {
	const [ipData, setIpData] = useState({
		ip: "loading...",
		location: "loading...",
		isp: "loading...",
		status: "loading...",
		status_color: null,
	});

	const [error, setError] = useState(null); // To handle errors

	useEffect(() => {
		const fetchData = async () => {
			try {
				// Step 1: Get the IP address
				const ipResponse = await fetch(
					"https://api.ipify.org/?format=json"
				);
				const ipJson = await ipResponse.json();
				const userIp = ipJson.ip;

				// Step 2: Get IP details
				const detailsResponse = await fetch(
					`https://ipapi.co/${userIp}/json/`
				);
				const detailsJson = await detailsResponse.json();

				// Update state with the fetched data
				setIpData({
					ip: detailsJson.ip,
					location: `${detailsJson.city}, ${detailsJson.region}`,
					isp: detailsJson.org,
					status: "UnProtected", // Example status
					status_color: "text-danger", // Example color
				});
			} catch (err) {
				setError("Failed to fetch IP details");
			}
		};

		fetchData();
	}, []);

	return (
		<div className="ip-info">
			<div className="ip-info-content content-wrapper-lg">
				<div className="ip-info-details-wrapper">
					<div className="ip-info-details">
						<div className="left-col">
							<div className="ip-info-details-item">
								<span className="ip-info-title">Your IP: </span>
								<span className="ip-info-value">
									{ipData.ip}
								</span>
							</div>
							<div className="ip-info-details-item">
								<span className="ip-info-title">
									Location:{" "}
								</span>
								<span className="ip-info-value">
									{ipData.location}
								</span>
							</div>
						</div>

						<div className="right-col">
							<div className="ip-info-details-item">
								<span className="ip-info-title">ISP: </span>
								<span className="ip-info-value">
									{ipData.isp}
								</span>
							</div>
							<div className="ip-info-details-item">
								<span className="ip-info-title">Status: </span>
								<span className={`ip-info-value ${ipData.status_color ?? ""}`}>
									{ipData.status}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
