import Header from "../../components/shared/Header";
import IPInfo from "../../components/shared/IPInfo";
import AboutUs from "./AboutUs";
import Features from "./Features";
import Hero from "./Hero";
import Footer from "../../components/shared/Footer";

export default function Home() {
	return (
		<>
			<IPInfo />
			
			<Header />

			<Hero />

			<AboutUs />

			<Features />

			<Footer />
		</>
	);
}
