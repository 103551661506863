import FeatureCard from "../../components/shared/FeatureCard";
import Button from "../../components/shared/Button";

import AiSearchEngineCoin1 from "../../assets/images/ai-search-engine-coin-1.png";
import AiSearchEngineCoin2 from "../../assets/images/ai-search-engine-coin-2.png";

import AdBlocker from "../../assets/images/ad-blocker.png";
import AdBlockerDec1 from "../../assets/images/ad-blocker-dec-1.png";
import AdBlockerDec2 from "../../assets/images/ad-blocker-dec-2.png";
import AdBlockerForbid from "../../assets/images/ad-blocker-forbid.png";

import Vpn from "../../assets/images/vpn.png";
import VpnDec1 from "../../assets/images/vpn-dec-1.png";
import VpnDec2 from "../../assets/images/vpn-dec-2.png";

import ContentFilter from "../../assets/images/content-filter.png";
import ContentFilterDec1 from "../../assets/images/content-filter-dec-1.png";
import ContentFilterDec2 from "../../assets/images/content-filter-dec-2.png";
import ContentFilterDec3 from "../../assets/images/content-filter-dec-3.png";
import ContentFilterDec4 from "../../assets/images/content-filter-dec-4.png";

import { ReactComponent as AiIcon } from "../../assets/icons/ai.svg";

import "./Features.scss";

export default function Features() {
	return (
		<>
			<div className="features" id="features">
				<div className="features-content content-wrapper-lg">
					<h1 className="features-title">Platform Features</h1>

					<div className="feature-cards">
						<div className="decoration feature-cards-light"></div>
						<div className="ai-search-engine feature-card-wrapper">
							<FeatureCard>
								<div className="feature-card-text">
									<h3 className="feature-card-title">
										<AiIcon className="ai-icon" />
										AI Search Engine
									</h3>

									<p className="feature-card-desc">
										Orion's AI Search Engine delivers fast,
										accurate, and private results, combining
										advanced algorithms and encryption to
										ensure secure, efficient, and anonymous
										browsing experiences.
									</p>

									<div className="feature-card-btns">
										<Button type="secondary">
											Try It Out
										</Button>
									</div>
								</div>

								<div className="feature-card-img">
									<div className="ai-search-img"></div>
									<div className="decoration ai-search-img-light"></div>
									<img
										className="coin-1"
										src={AiSearchEngineCoin1}
										alt="Coin 1"
										data-aos="zoom-in-right"
									/>
									<img
										className="coin-2"
										src={AiSearchEngineCoin2}
										alt="Coin 2"
										data-aos="zoom-in-left"
									/>
								</div>
							</FeatureCard>
						</div>

						<div className="ad-blocker feature-card-wrapper">
							<FeatureCard className="reverse">
								<div className="feature-card-img">
									<img
										src={AdBlocker}
										alt="Ad Blocker"
										className="ad-blocker-img"
									/>
									<img
										className="dec-1"
										src={AdBlockerDec1}
										alt="Ad Blocker Dec 1"
										data-aos="zoom-in-right"
										data-aos-delay="150"
									/>
									<img
										className="dec-2"
										src={AdBlockerDec2}
										alt="Ad Blocker Dec 2"
										data-aos="zoom-in-left"
										data-aos-delay="150"
									/>
									<div className="decoration light-1"></div>
									<img
										src={AdBlockerForbid}
										alt="Ad Blocker Forbid"
										className="forbid-icon"
										data-aos="zoom-out"
									/>
								</div>

								<div className="feature-card-text">
									<h3 className="feature-card-title">
										Extensive Ad Blocker
									</h3>

									<p className="feature-card-desc">
										Orion's Ad Blocker extension eliminates
										intrusive ads, enhances browsing speed,
										and protects privacy by blocking
										trackers, ensuring a seamless,
										distraction-free, and secure online
										experience.
									</p>

									<div className="feature-card-btns">
										<Button type="secondary">
											Try It Out
										</Button>
									</div>
								</div>
							</FeatureCard>
						</div>

						<div className="vpn feature-card-wrapper">
							<FeatureCard>
								<div className="feature-card-text">
									<h3 className="feature-card-title">
										Orion VPN
									</h3>

									<p className="feature-card-desc">
										This feature enhances privacy by masking
										IP addresses through centralized
										servers, encrypting data, and preventing
										tracking, ensuring safe, anonymous, and
										reliable browsing experiences globally.
									</p>

									<div className="feature-card-btns">
										<Button type="secondary">
											Try It Out
										</Button>
									</div>
								</div>

								<div className="feature-card-img">
									<img src={Vpn} alt="Vpn" />
									<img
										className="dec-1"
										src={VpnDec1}
										alt="Vpn Dec 1"
										data-aos="zoom-in-left"
									/>
									<img
										className="dec-2"
										src={VpnDec2}
										alt="Vpn Dec 2"
										data-aos="zoom-in-right"
									/>
									<div className="decoration light-1"></div>
								</div>
							</FeatureCard>
						</div>

						<div className="content-filter feature-card-wrapper">
							<FeatureCard className="reverse">
								<div className="feature-card-img">
									<img
										src={ContentFilter}
										alt="Content Filter"
									/>
									<img
										className="dec-1"
										src={ContentFilterDec1}
										alt="Content Filter Dec 1"
										data-aos="zoom-in-left"
										data-aos-delay="150"
									/>
									<img
										className="dec-2"
										src={ContentFilterDec2}
										alt="Content Filter Dec 2"
										data-aos="zoom-in-right"
										data-aos-delay="150"
									/>
									<img
										className="dec-3"
										src={ContentFilterDec3}
										alt="Content Filter Dec 3"
										data-aos="zoom-in-left"
										data-aos-delay="150"
									/>
									<img
										className="dec-4"
										src={ContentFilterDec4}
										alt="Content Filter Dec 4"
										data-aos="zoom-in-right"
										data-aos-delay="150"
									/>
								</div>

								<div className="feature-card-text">
									<h3 className="feature-card-title">
										Content Filter
									</h3>

									<p className="feature-card-desc">
										Orion's Content Filter Extension
										empowers users to control online
										content, block unwanted material, and
										create a safer, personalized browsing
										experience tailored to their
										preferences.
									</p>

									<div className="feature-card-btns">
										<Button type="secondary">
											Try It Out
										</Button>
									</div>
								</div>
							</FeatureCard>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
