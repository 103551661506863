import "./AboutUs.scss";

import aboutUsCoin1 from "../../assets/images/about-us-coin-1.png";
import aboutUsCoin2 from "../../assets/images/about-us-coin-2.png";
import aboutUsDec1 from "../../assets/images/about-us-dec-1.png";
import aboutUsDec2 from "../../assets/images/about-us-dec-2.png";

import Button from "../../components/shared/Button";

export default function AboutUs() {
	return (
		<>
			<div className="about-us">
				<div className="about-us-content content-wrapper-lg">
					<div className="about-us-light-1"></div>

					<h1 className="about-us-title" data-aos="fade-up">
						About Us
					</h1>

					<div className="about-us-cards row">
						<div className="about-us-card-col">
							<div className="about-us-card card-1">
								<div className="about-us-card-img">
									<img
										src={aboutUsCoin1}
										alt="About Us Coin 1"
									/>
								</div>

								<h4 className="about-us-card-title">Orion</h4>

								<p className="about-us-card-text">
									Orion is a browser designed to prioritize
									privacy and security, ensuring a safe and
									anonymous online experience. It empowers
									users to browse confidently, free from risk.
								</p>

								<div className="about-us-btns">
									<Button>Show More</Button>
								</div>

								<img
									src={aboutUsDec1}
									alt="About Us Decoration 1"
									className="about-us-dec-1"
									data-aos="fade-right"
								/>
							</div>
						</div>

						<div className="about-us-card-col">
							<div className="about-us-card card-2">
								<div className="about-us-card-img">
									<img
										src={aboutUsCoin2}
										alt="About Us Coin 2"
									/>
								</div>

								<h4 className="about-us-card-title">
									Google Chrome
								</h4>

								<p className="about-us-card-text">
									All extensions provided by Orion are fully
									compatible with Google Chrome, ensuring a
									seamless browsing experience. Users can
									easily access these extensions directly
									within their Chrome browser for added
									convenience.
								</p>

								<div className="about-us-btns">
									<Button type="secondary">Show More</Button>
								</div>

								<img
									src={aboutUsDec2}
									alt="About Us Decoration 2"
									className="about-us-dec-2"
									data-aos="fade-left"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
