import "./Hero.scss";
import Button from "../../components/shared/Button";
import LogoText from "../../assets/images/logo-text.png";

import heroOrion from "../../assets/images/hero/Orion.png";
import heroColumnLeft from "../../assets/images/hero/column - Left.png";
import heroColumnMiddle from "../../assets/images/hero/column - Middle.png";
import heroColumnRight from "../../assets/images/hero/column - Right.png";
import heroEtheriumBlur from "../../assets/images/hero/Etherium - Blur.png";
import heroEtherium from "../../assets/images/hero/Etherium.png";
import heroOrionBlur from "../../assets/images/hero/Orion - Blur.png";
import heroOrion3d from "../../assets/images/hero/Orion 3d.png";

import orionCoins1 from "../../assets/images/hero/orion-coins-1.png";
import orionCoins2 from "../../assets/images/hero/orion-coins-2.png";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";

export default function Hero() {
	return (
		<>
			<div className="hero">
				<div className="hero-content content-wrapper-lg">
					<div className="hero-text">
						<h1 className="hero-title" data-aos="fade-up">
							Browse Securely with{" "}
							<img
								src={LogoText}
								alt="Orion"
								className="hero-title-logo"
							/>
						</h1>

						<p
							className="hero-desc"
							data-aos="fade-up"
							data-aos-delay="100"
						>
							Your all-in-one platform for private, secure, and
							seamless browsing
						</p>

						<div
							className="hero-btns"
							data-aos="fade-up"
							data-aos-delay="200"
						>
							<Button>
								Get Started!
								<ArrowRight
									className="btn-icon"
									width={18}
									height={18}
								/>
							</Button>
						</div>
					</div>

					<div className="hero-image-wrapper">
						<div className="hero-image">
							<div className="hero-image-parts">
								<div className="light light-1"></div>
								<div className="light light-2"></div>

								<img
									src={heroOrion}
									alt="Orion"
									className="hero-image-part hero-orion shake-slow-5"
								/>
								<img
									src={heroColumnLeft}
									alt="Column Left"
									className="hero-image-part hero-column-left"
								/>
								<img
									src={heroColumnMiddle}
									alt="Column Middle"
									className="hero-image-part hero-column-middle"
								/>
								<img
									src={heroColumnRight}
									alt="Column Right"
									className="hero-image-part hero-column-right"
								/>
								<img
									src={heroEtheriumBlur}
									alt="Etherium Blur"
									className="hero-image-part hero-etherium-blur shake-slow-4"
								/>
								<img
									src={heroEtherium}
									alt="Etherium"
									className="hero-image-part hero-etherium shake-slow-3"
								/>
								<img
									src={heroOrionBlur}
									alt="Orion Blur"
									className="hero-image-part hero-orion-blur shake-slow-2"
								/>
								<img
									src={heroOrion3d}
									alt="Orion 3d"
									className="hero-image-part hero-orion-3d shake-slow-1"
								/>
								<img
									src={orionCoins1}
									alt="Orion Coin"
									className="hero-image-part orion-coins-1 shake-slow-6"
								/>

								<img
									src={orionCoins2}
									alt="Orion Coin"
									className="hero-image-part orion-coins-2 shake-slow-7"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
