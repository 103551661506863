import { Route, Routes } from "react-router-dom";
import Home from "./Home/Home";

import 'aos/dist/aos.css';
import { useEffect } from "react";
import AOS from "aos";

export default function App() {
    useEffect(() => {
        AOS.init({
            duration: 1500,
        });
    }, []);
    
    return <>
        <Routes>
            <Route path="/" element={<Home />} />
        </Routes>
    </>
}
