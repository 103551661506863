import "./Footer.scss";
import logoText2 from "../../assets/images/logo-text-2.png";

import footerLeftCol1 from "../../assets/images/footer/left-column-1.png";
import footerLeftCoin1 from "../../assets/images/footer/left-coin-1.png";
import footerLeftCol2 from "../../assets/images/footer/left-column-2.png";
import footerLeftCoin2 from "../../assets/images/footer/left-coin-2.png";
import footerRightCol1 from "../../assets/images/footer/right-column-1.png";
import footerRightCoin1 from "../../assets/images/footer/right-coin-1.png";
import footerRightCol2 from "../../assets/images/footer/right-column-2.png";
import footerRightCoin2 from "../../assets/images/footer/right-coin-2.png";

import { ReactComponent as TelegramIcon } from "../../assets/icons/Telegram.svg";
import { ReactComponent as GitbookIcon } from "../../assets/icons/Gitbook.svg";
import { ReactComponent as MediumIcon } from "../../assets/icons/Medium.svg";
import { ReactComponent as DexToolsIcon } from "../../assets/icons/DexTools.svg";

export default function Footer() {
	return (
		<div className="footer">
			<div className="footer-content-wrap content-wrapper-lg">
				<div className="decoration light-1"></div>
				<div className="decoration light-2"></div>
				<div className="decoration light-3"></div>
				<div className="decoration light-4"></div>

				<div className="footer-content">
					<div className="footer-logo">
						<img src={logoText2} alt="Logo" />
					</div>

					<p className="footer-desc">
						Your all-in-one platform for private, secure, and
						seamless browsing
					</p>

					<div className="footer-links-wrapper">
						<ul className="footer-links">
							<li>
								<a href="https://t.me/OrionBrowse">
									<TelegramIcon className="footer-link-icon" />
									Telegram
								</a>
							</li>
							<li>
								<a href="https://orion-11.gitbook.io/">
									<GitbookIcon className="footer-link-icon" />
									GitBook
								</a>
							</li>
							<li>
								<a href="https://medium.com/@teamorionerc">
									<MediumIcon className="footer-link-icon" />
									Medium
								</a>
							</li>
							<li>
								<a href="#">
									<DexToolsIcon className="footer-link-icon" />
									Dex Tools
								</a>
							</li>
						</ul>
					</div>

					<div className="footer-copyright">
						<p>
							Copyright © 2024 - 2024 Orion®. All rights reserved.
						</p>
					</div>
				</div>

				<div className="decoration footer-left-col-1">
					<img
						className="coin-img shake-slow-5"
						src={footerLeftCoin1}
						alt="Footer Left Coin 1"
					/>
					<img
						className="col-img"
						src={footerLeftCol1}
						alt="Footer Left Column 1"
					/>
				</div>

				<div className="decoration footer-left-col-2">
					<img
						className="coin-img shake-slow-4"
						src={footerLeftCoin2}
						alt="Footer Left Coin 2"
					/>
					<img
						className="col-img"
						src={footerLeftCol2}
						alt="Footer Left Column 2"
					/>
				</div>

				<div className="decoration footer-right-col-1">
					<img
						className="coin-img shake-slow-3"
						src={footerRightCoin1}
						alt="Footer Right Coin 1"
					/>
					<img
						className="col-img"
						src={footerRightCol1}
						alt="Footer Right Column 1"
					/>
				</div>

				<div className="decoration footer-right-col-2">
					<img
						className="coin-img shake-slow-2"
						src={footerRightCoin2}
						alt="Footer Right Coin 2"
					/>
					<img
						className="col-img"
						src={footerRightCol2}
						alt="Footer Right Column 2"
					/>
				</div>
			</div>
		</div>
	);
}
