import React from 'react';
import './ButtonOutline.scss';

export default function ButtonOutline({ children, className, onClick, href, onMouseEnter, onMouseLeave}) {
    const classes = `btn-outline ${className}`;

    return href ? (
        <a href={href} className={classes} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {children}
        </a>
    ) : (
        <button className={classes} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {children}
        </button>
    );
}
